<template>
  <div class="wrap">
    <div class="search-container">
      <img
        class="more-images"
        src="../../../assets/images/venu_more.png"
        alt=""
        srcset=""
      />
      <el-row style="line-height: 43px">
        <el-col :span="17">
          <!-- <el-form ref="form" :model="form" label-width="80px"> -->
          <el-row>
            <el-col :span="24">
              <!-- <el-form-item label="场馆类型"> -->
              <label class="venue-lable" for="">场馆类型:</label>
              <el-radio-group
                class="venue-radio-wrapper"
                @change="searchEvent"
                v-model="form.mainDirection"
                size="mini"
              >
                <el-radio-button class="radio" label="" checked="checked">{{
                  $t("allBtn")
                }}</el-radio-button>
                <el-radio-button
                  class="radio"
                  v-for="item in DROPDOWNBOX.VENUE_MAIN_DIRECTION"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}</el-radio-button
                >
              </el-radio-group>
              <!-- </el-form-item> -->
            </el-col>
          </el-row>
          <!-- </el-form> -->
        </el-col>
        <!-- <el-col :span="4" style="">
        </el-col> -->
        <el-col :span="7" class="head-left-wrapper">
          <div class="input-wrapper">
            <el-input
              @keyup.enter.native="searchEvent"
              size="mini"
              placeholder="请输入场馆名称"
              v-model="form.venueName"
            ></el-input>
            <img
              @click="searchEvent"
              class="input-image"
              src="../../../assets/images/input_search.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="btn-wrapper">
            <el-button
              class="btn-round-style add-btn"
              v-if="buttonAuth.includes('venueMana:venue:add')"
              @click="changeAddDialog(true)"
              >创建场馆</el-button
            >
          </div>
          <!-- <el-button @click="searchEvent" size="mini" class="search-button">{{$t('search')}}</el-button> -->
          <!-- <el-button @click="cleanForm" size="mini" class="clean-button">{{$t('clean')}}</el-button> -->
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <div class="venue-wrap" v-for="item in tableData" :key="item.id">
        <p class="title">{{ item.venueName }}</p>
        <div class="icon-wrap">
          <!-- {{item}} -->
          <img
            src="@/assets/images/Fill 2@2x.png"
            v-if="buttonAuth.includes('venueMana:venue:edit')"
            @click="changeAddDialog(true, true, item)"
          />
          <img
            src="@/assets/images/Fill 1@2x.png"
            v-if="buttonAuth.includes('venueMana:venue:remove')"
            @click="openDeleteDialog([item.id])"
            alt
          />
        </div>
        <p class="deadline">使用期限： 2070年1月1日</p>
        <span
          class="maintain"
          v-if="buttonAuth.includes('venueMana:venue:venueSet')"
          @click="changeDefendDialog(true, true, item.id)"
          >场地维护</span
        >
        <span
          class="maintain maintain-new"
          @click="toServe()"
          >商品及服务</span
        >
        <p class="address">
          <img
            class="address-images"
            src="../../../assets/images/venue_pos.png"
            alt=""
            srcset=""
          />
          {{ item.address }}
        </p>
      </div>
    </div>
    <!-- <div class="page-container">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div> -->
    <div class="button-container">
      <!-- <el-button class="default-button">{{$t('delete')}}</el-button> -->
      <!-- <el-button class="default-button" v-if="buttonAuth.includes('venueMana:venue:add')" @click="changeAddDialog(true)">{{$t('add')}}</el-button> -->
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      @closeDialog="changeAddDialog"
    ></add-dialog>
    <!-- 场地维护弹窗 -->
    <defend-dialog
      ref="defendDialog"
      :Language="Language"
      :show="defendDialog.visible"
      :isEdit="defendDialog.isEdit"
      @closeDialog="changeDefendDialog"
    ></defend-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog
      ref="confirmDialog"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VenueSearchClass from "./venueSearchClass";
import apis from "@/apis";
import mixin from "@/mixins/index";
import addDialog from "./dialog/addDialog.vue";
import confirmDialog from "@/components/confirmDialog.vue";
import defendDialog from "./dialog/defendDialog.vue";

const deepMap = {};
const venueType = [
  "全部",
  "游泳馆",
  "羽毛球馆",
  "篮球馆",
  "乒乓球馆",
  "网球馆",
];

export default {
  components: {
    addDialog,
    confirmDialog,
    defendDialog,
  },
  mixins: [mixin],
  data() {
    return {
      form: new VenueSearchClass("form"), // 实例化一个表单的变量
      deeps: [
        { value: 0, label: "省" },
        { value: 1, label: "市" },
        { value: 2, label: "区/县" },
      ],
      mainDirection: "",
      venueType,
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      defendDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
    };
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", "venueId", "ROLES"]),
  },
  filters: {
    deepFilter(type) {
      return deepMap[type].text;
    },
  },
  created() {
    console.log(this.ROLES);
    this.deeps.forEach((d) => {
      deepMap[d.value] = { text: d.label };
    });
  },
  methods: {
    toServe() {
     this.$router.push({ name: 'Service' })
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      console.log("this.form", this.form);
      this.$http.post(apis.venuevManagementList, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
        }
      });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new VenueSearchClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeDefendDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs["defendDialog"].getDataQuery(data);
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchData();
      }
      this.$nextTick(() => {
        this.defendDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          console.log(data, "data+++++++++++++");
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs["addDialog"].getDataQuery(data);
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchData();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // console.log(this.confirmDialog.data.ids)
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http
        .post(
          apis.venuevManagementDeleteVenuevManagement,
          { id: this.confirmDialog.data.ids },
          {},
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
            if (this.confirmDialog.data.ids === this.venueId) {
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            }
            this.searchData();
          }
        });
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      if (id.length === 0) {
        this.$message.error("请先选择数据");
        return;
      }
      this.confirmDialog.data.ids = id.join(",");
      this.changeConfirmDialog(true, 1);
    },
    /**
     * @function 打开确认重置密码弹窗
     * @param {Array} data 要重置密码的值
     */
    openConfirmDialog(data) {
      this.confirmDialog.data = data;
      this.changeConfirmDialog(true, 2);
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.ids = "";
        this.searchEvent();
      }
    },
    /**
     * @function 修改用户启用状态
     * @param {Object} data 用户信息
     */
    changeStatus(data) {
      const form = {
        ...data,
        ...{
          status: data.status === "1" ? "0" : "1",
        },
      };
      this.$http.post(apis.userStatus, form);
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.wrap{
  height: 100%;
  overflow-y: auto;
}
.search-container {
  position: relative;
  border-bottom: 1px solid rgba(238, 240, 244, 1);
  .more-images {
    position: absolute;
    width: 42px;
    height: 12px;
    bottom: -12px;
    left: 50%;
  }
}
.head-left-wrapper {
  display: flex;
  // flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}
.input-wrapper {
  width: 195px;
  position: relative;
}
.input-image {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
// .btn-wrapper {
//   margin-left: 30px;
// }
.venue-lable {
  color: rgba(21, 21, 21, 1);
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
}
.table-container {
  width: 100%;
  // height: 600px;
  height: calc(100% - 300px);
  overflow-y: scroll;
  margin-top: 30px;
}
.radio {
  margin-top: 5px;
  margin-bottom: 5px;
}
.venue-wrap {
  width: 356px;
  height: 182px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  float: left;
  margin-left: 20px;
  position: relative;
  margin-top: 20px;
  box-shadow: 0px 2px 12px 0px rgba(130, 130, 130, 0.17);
  border: 1px solid #ffffff;
  &:hover {
    background: rgba(68, 155, 255, 0.3);
    border: 1px solid rgba(68, 155, 255, 1);
  }
  .maintain {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 14px;
    color: #14b500;
    cursor: pointer;
  }
  .maintain-new {
    top: 55px;
  }
  .title {
    font-size: 14px;
    font-family: SourceHanSansSC-Bold, SourceHanSansSC;
    font-weight: bold;
    color: rgba(79, 79, 79, 1);
    // line-height: 40px;
    padding: 20px 0 20px 20px;
    width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .icon-wrap {
    width: 100%;
    height: 42px;
    img {
      height: 20px;
      width: 20px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .address {
    font-size: 14px;
    font-family: SourceHanSansSC-Normal, SourceHanSansSC;
    font-weight: 400;
    color: rgba(79, 79, 79, 1);
    margin-left: 20px;
    margin-top: 19px;
    margin-right: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .address-images {
      width: 11px;
      height: 16px;
      display: inline-block;
      margin-right: 7px;
      vertical-align: middle;
    }
  }
  .deadline {
    margin-left: 20px;
    font-size: 14px;
    color: rgba(79, 79, 79, 1);
  }
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.venue-radio-wrapper {
  .el-radio-button__inner {
    margin-right: 10px !important;
    background: rgba(238, 240, 244, 1) !important;
    border-radius: 4px !important;
    color: rgba(172, 174, 176, 1) !important;
    font-size: 14px !important;
    border: 1px solid rgba(238, 240, 244, 1) !important;
  }
}
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  border-radius: 4px !important;
  border: 1px solid rgba(68, 155, 255, 1) !important;
  font-size: 14px !important;
  color: rgba(68, 155, 255, 1) !important;
  background: rgba(68, 155, 255, 0.3) !important;
}
</style>
