/*
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2020-06-12 17:50:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\venue\dialog\addDialogClass.js
 */
/* eslint-disable */
class searchFrom {
  validatePhone = (rule, value, callback) => {
    var phone = value.replace(/\s/g, "");//去除空格
    //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
    let regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
    let reg = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/;
    if (!value) {
      callback([new Error('请输入联系电话')]);
    } else {
      if (!regs.test(phone) && !reg.test(phone)) {
        callback([new Error('电话输入不正确')]);
      } else {
        callback();
      }
    }
  }
  constructor(type) {
    if (type === 'form') {
      this.status = 'first'
      this.mainDirection = []
      this.accommodateQty = ''
      this.address = ''
      this.venueCircleLogo = ""
      this.briefIntroduction = ''
      this.businessHours = []
      this.point = {}
      this.latitude = '21'
      this.longitude = '21'
      this.mapAnnotation = ''
      this.openTime = ''
      this.score = ''
      this.service = []
      this.longitude = ''
      this.latitude = ''
      this.telephone = ''
      this.venueLogo = ''
      this.venueName = ''
      this.promotionPictures = []
      this.businessId = '1'
      this.closeTime = ''
      this.faceDeviceSupport = 'N' // 人脸识别
      this.mapIcon = ''
      this.reservationSupport = 'Y' // 场馆状态
      this.merchantAccountName = '' // 商户名称
      this.merchantAccountId = ''
      this.entityBaseInfoId = '' // 商户主体id
      this.entityBaseInfoName = '' // 商户主体名称
      // 自己用
      this.venueLogoPath = ''
      this.promotionPicturesPath = []
      this.mapAnnotation = ""
      this.vrSupport = "N"
      this.vrPath = ''
    } else if (type === 'rule') {
      this.mainDirection = [{ required: true, message: '请选择主营方向', trigger: ['change', 'blur'] }]
      this.venueName = [{ required: true, message: '请输入场馆名称', trigger: ['change', 'blur'] }]
      this.accommodateQty = [{ required: true, message: '请输入场馆可容纳人数', trigger: ['change', 'blur'] }]
      this.address = [{ required: true, message: '请输入场馆地址', trigger: ['change', 'blur'] }]
      this.briefIntroduction = [{ required: true, message: '请输入场馆简介', trigger: ['change', 'blur'] }]
      this.venueLogo = [{ required: true, message: '请上传场馆LOGO', trigger: ['change', 'blur'] }]
      this.telephone = [{ required: true, message: '请输入联系电话', trigger: ['blur', 'change'] }]
      this.businessHours = [{ required: true, message: '请选择场馆营业时间', trigger: ['blur', 'change'] }]
      this.openTime = [{ required: true, message: '请输入场馆开馆时间', trigger: ['blur', 'change'] }]
      this.closeTime = [{ required: true, message: '请输入场馆闭馆时间', trigger: ['blur', 'change'] }]
      this.promotionPictures = [{ required: true, message: '请上传推广图片', trigger: 'blur' }]
      this.service = [{ required: false, message: '请选择场馆对应的服务', trigger: ['blur', 'change'] }]
      this.point = [{ required: true, message: '请在地图上进行场馆标注', trigger: ['change', 'blur'] }]
      this.vrSupport = [{ required: true, message: '请选择是否支持VR', trigger: ['change', 'blur'] }]
      this.vrPath = [{ required: true, message: '请输入VR地址', trigger: ['change', 'blur'] }]
      this.faceDeviceSupport = [{ required: true, message: '请选择是否支持人脸识别', trigger: ['change', 'blur'] }]
      this.reservationSupport = [{ required: true, message: '请选择是否支持人脸识别', trigger: ['change', 'blur'] }]
      this.mapIcon = [{ required: true, message: '请选择地图图标', trigger: ['blur', 'change'] }]
    }
  }
}
export default searchFrom
